export default [
    {
        path: "/Tasting/",
        name: "Tasting",
        meta: {
            title: process.env.VUE_APP_NAME,
            keepAlive: false,
            backgroundColor: "#f5f5f5"
        },
        component: () => import("@views/Tasting/Index.vue"),
        children: [
            {
                path: "Index",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#f5f5f5",
                    cur: 0,
                },
                component: () => import("@views/Tasting/BrandCenter.vue")
            },
            // {
            //     path: "BrandCenter",
            //     name: "Tasting",
            //     meta: {
            //         title: process.env.VUE_APP_NAME,
            //         backgroundColor: "#f5f5f5",
            //         cur: 1,
            //     },
            //     component: () => import("@views/Tasting/BrandCenter.vue")
            // },
            {
                path: "BrandCenters",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#f5f5f5",
                    cur: 1,
                },
                component: () => import("@views/Tasting/BrandCenters.vue")
            },
            {
                path: "goods/detail",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#f5f5f5",
                    cur: 1,
                },
                component: () => import("@views/Tasting/goods/Detail.vue")
            },
            {
                path: "wine/list",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#ffffff",
                    cur: 2,
                },
                component: () => import("@views/Tasting/wine/List.vue")
            },
            {
                path: "wine/info",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#f5f5f5",
                    cur: 2,
                },
                component: () => import("@views/Tasting/wine/info.vue")
            },
            {
                path: "PTasting",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#ffffff",
                    cur: 3,
                },
                component: () => import("@views/Tasting/PTasting/PTasting.vue")
            },
            {
                path: "video/list",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#f5f5f5",
                    cur: 3,
                },
                component: () => import("@views/Tasting/video/list.vue")
            },
            
        ]
    },
    {
        path: "/Tasting/goods/Salelist",
        name: "Tasting",
        meta: {
            title: process.env.VUE_APP_NAME,
            backgroundColor: "#f5f5f5",
            cur: 1,
        },
        component: () => import("@views/Tasting/goods/Salelist.vue")
    },
    {
        path: "/Tasting/",
        name: "Tasting",
        meta: {
            title: process.env.VUE_APP_NAME,
            keepAlive: false,
            backgroundColor: "#f5f5f5"
        },
        component: () => import("@views/Tasting/Footer.vue"),
        children: [
            {
                path: "/Tasting/user",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#f5f5f5"
                },
                component: () => import("@views/Tasting/user/user.vue")
            },
            {
                path: "/Tasting/user/login",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#f5f5f5"
                },
                component: () => import("@views/Tasting/user/Login.vue")
            },
        ]
    },
    {
        path: "/Tasting/",
        name: "Tasting",
        meta: {
            title: process.env.VUE_APP_NAME,
            keepAlive: false,
            backgroundColor: "#f5f5f5"
        },
        component: () => import("@views/Tasting/head.vue"),
        children: [
            {
                path: "/Tasting/news/list",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#f5f5f5",
                },
                component: () => import("@views/Tasting/news/list.vue")
            },
            {
                path: "/Tasting/news/info",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#ffffff",
                },
                component: () => import("@views/Tasting/news/info.vue")
            },
            {
                path: "/Tasting/wine/commentinfo",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#ffffff",
                },
                component: () => import("@views/Tasting/wine/commentinfo.vue")
            },
            {
                path: "/Tasting/wine/zan",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#ffffff",
                },
                component: () => import("@views/Tasting/wine/zan.vue")
            },
            {
                path: "/Tasting/PTasting/Release",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#f5f5f5",
                },
                component: () => import("@views/Tasting/PTasting/Release.vue")
            },
            {
                path: "/Tasting/wine/Release_share",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#f5f5f5",
                },
                component: () => import("@views/Tasting/wine/Release_share.vue")
            },
            {
                path: "/Tasting/goods/Winery",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#f5f5f5",
                },
                component: () => import("@views/Tasting/goods/Winery.vue")
            },
            {
                path: "/Tasting/goods/videoinfo",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#f5f5f5",
                },
                component: () => import("@views/Tasting/goods/videoinfo.vue")
            },
            {
                path: "/Tasting/goods/piclist",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#ffffff",
                },
                component: () => import("@views/Tasting/goods/piclist.vue")
            },
            {
                path: "/Tasting/cityselect",
                name: "Tasting",
                meta: {
                    title: '城市选择',
                    backgroundColor: "#f5f5f5"
                },
                component: () => import("@views/Tasting/Cityselect.vue")
            },
            {
                path: "/Tasting/Search",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#f5f5f5"
                },
                component: () => import("@views/Tasting/Search.vue")
            },
            {
                path: "/Tasting/user",
                name: "Tasting",
                meta: {
                    title: process.env.VUE_APP_NAME,
                    backgroundColor: "#f5f5f5"
                },
                component: () => import("@views/Tasting/user/user.vue")
            },
            
            
        ]
    },
    {
        path: "/Tasting/ad/index",
        name: "Tasting",
        meta: {
            title: process.env.VUE_APP_NAME,
            backgroundColor: "#f5f5f5",
            cur: 1,
        },
        component: () => import("@views/Tasting/ad/index.vue")
    },
    {
        path: "/Tasting/user/dynamic/list",
        name: "Tasting",
        meta: {
            title: '动态列表',
            backgroundColor: "#f5f5f5"
        },
        component: () => import("@views/Tasting/user/dynamic/list.vue")
    },
    {
        path: "/Tasting/user/dynamic/add",
        name: "Tasting",
        meta: {
            title: '发布动态',
            backgroundColor: "#f5f5f5"
        },
        component: () => import("@views/Tasting/user/dynamic/add.vue")
    },
    {
        path: "/Tasting/user/follow",
        name: "Tasting",
        meta: {
            title: '关注与粉丝',
            backgroundColor: "#f5f5f5"
        },
        component: () => import("@views/Tasting/user/follow/followlist.vue")
    },
    {
        path: "/Tasting/user/fans",
        name: "Tasting",
        meta: {
            title: process.env.VUE_APP_NAME,
            backgroundColor: "#f5f5f5"
        },
        component: () => import("@views/Tasting/user/follow/fanslist.vue")
    },
    {
        path: "/Tasting/user/recommend",
        name: "Tasting",
        meta: {
            title: process.env.VUE_APP_NAME,
            backgroundColor: "#f5f5f5"
        },
        component: () => import("@views/Tasting/user/follow/recommend.vue")
    },
    {
        path: "/Tasting/wine/Release",
        name: "Tasting",
        meta: {
            title: process.env.VUE_APP_NAME,
            backgroundColor: "#ffffff",
        },
        component: () => import("@views/Tasting/wine/Release.vue")
    },
    {
        path: "/Tasting/user/setStore",
        name: "Tasting",
        meta: {
            title: '设置店铺',
            backgroundColor: "#ffffff",
        },
        component: () => import("@views/Tasting/user/setstore/store.vue")
    },
    {
        path: "/Tasting/saoma/index",
        name: "Tasting",
        meta: {
            title: process.env.VUE_APP_NAME,
            backgroundColor: "#f5f5f5",
        },
        component: () => import("@views/Tasting/saoma/index.vue")
    },
    {
        path: "/Tasting/user/visitor",
        name: "Tasting",
        meta: {
            title: '访客列表',
            backgroundColor: "#f5f5f5",
        },
        component: () => import("@views/Tasting/user/visitor.vue")
    },
    {
        path: "/Tasting/user/storeVisitor",
        name: "Tasting",
        meta: {
            title: '用户列表',
            backgroundColor: "#f5f5f5",
        },
        component: () => import("@views/Tasting/user/storeVisitor.vue")
    },
    {
        path: "/Tasting/user/scanuser",
        name: "Tasting",
        meta: {
            title: '扫码数据统计',
            backgroundColor: "#f5f5f5",
        },
        component: () => import("@views/Tasting/user/scancode/scanuser.vue")
    },
    {
        path: "/Tasting/user/scancode",
        name: "Tasting",
        meta: {
            title: '条码列表',
            backgroundColor: "#f5f5f5",
        },
        component: () => import("@views/Tasting/user/scancode/scancode.vue")
    },
    {
        path: "/Tasting/user/judge/index",
        name: "Tasting",
        meta: {
            title: '我的品鉴',
            backgroundColor: "#ffffff",
        },
        component: () => import("@views/Tasting/user/judge/index.vue")
    },
    {
        path: "/Tasting/user/judge/examine",
        name: "Tasting",
        meta: {
            title: '品鉴审核',
            backgroundColor: "#ffffff",
        },
        component: () => import("@views/Tasting/user/judge/examine.vue")
    },
    {
        path: "/Tasting/chart",
        name: "Tasting",
        meta: {
            title: '图表',
            backgroundColor: "#ffffff",
        },
        component: () => import("@views/Tasting/user/scancode/chart.vue")
    },
    {
        path: "/Tasting/message/list",
        name: "Tasting",
        meta: {
            title: '留言列表',
            backgroundColor: "#f5f5f5",
        },
        component: () => import("@views/Tasting/user/message/list.vue")
    },
    {
        path: "/Tasting/message/customer/:id",
        name: "Tasting",
        meta: {
            title: '留言窗',
            backgroundColor: "#f5f5f5",
        },
        component: () => import("@views/Tasting/user/message/customer.vue")
    },
    {
        path: "/Tasting/user/ActivityCode",
        name: "Tasting",
        meta: {
            title: '活动码核销',
            backgroundColor: "#f5f5f5",
        },
        component: () => import("@views/Tasting/user/ActivityCode.vue")
    },
    {
        path: "/Tasting/user/exchangeList",
        name: "Tasting",
        meta: {
            title: '兑换列表',
            backgroundColor: "#f5f5f5",
        },
        component: () => import("@views/Tasting/user/exchangeList.vue")
    },
    {
        path: "/Tasting/user/applyForm",
        name: "Tasting",
        meta: {
            title: '生成店面',
            backgroundColor: "#f5f5f5",
        },
        component: () => import("@views/Tasting/user/applyForm.vue")
    },
    {
        path: "/Tasting/user/setting",
        name: "Tasting",
        meta: {
            title: '商品设置',
            backgroundColor: "#f5f5f5",
        },
        component: () => import("@views/Tasting/user/setting.vue")
    },
    {
        path: "/Tasting/goods/videoExplain",
        name: "Tasting",
        meta: {
            title: '商品详情',
            backgroundColor: "#ffffff",
        },
        component: () => import("@views/Tasting/goods/videoExplain.vue")
    },
    {
        path: "/Pisces/index.html",
        name: "Tasting",
        meta: {
            title: '双鱼座',
            backgroundColor: "#ffffff",
        },
        component: () => import("@views/Tasting/oldwcj/Pisces.vue")
    },
    {
        path: "/Aquarius/index.html",
        name: "Tasting",
        meta: {
            title: '水瓶座',
            backgroundColor: "#ffffff",
        },
        component: () => import("@views/Tasting/oldwcj/Aquarius.vue")
    },
    {
        path: "/Aries/index.html",
        name: "Tasting",
        meta: {
            title: '白羊座',
            backgroundColor: "#ffffff",
        },
        component: () => import("@views/Tasting/oldwcj/Aries.vue")
    },
    {
        path: "/Taurus/index.html",
        name: "Tasting",
        meta: {
            title: '金牛座',
            backgroundColor: "#ffffff",
        },
        component: () => import("@views/Tasting/oldwcj/Taurus.vue")
    },
    {
        path: "/Cancer/index.html",
        name: "Tasting",
        meta: {
            title: '巨蟹座',
            backgroundColor: "#ffffff",
        },
        component: () => import("@views/Tasting/oldwcj/Cancer.vue")
    },
    {
        path: "/Gemini/index.html",
        name: "Tasting",
        meta: {
            title: '双子座',
            backgroundColor: "#ffffff",
        },
        component: () => import("@views/Tasting/oldwcj/Gemini.vue")
    },
    {
        path: "/Virgo/index.html",
        name: "Tasting",
        meta: {
            title: '处女座',
            backgroundColor: "#ffffff",
        },
        component: () => import("@views/Tasting/oldwcj/Virgo.vue")
    },
    {
        path: "/Libra/index.html",
        name: "Tasting",
        meta: {
            title: '天秤座',
            backgroundColor: "#ffffff",
        },
        component: () => import("@views/Tasting/oldwcj/Libra.vue")
    },
    {
        path: "/Scorpio/index.html",
        name: "Tasting",
        meta: {
            title: '天蝎座',
            backgroundColor: "#ffffff",
        },
        component: () => import("@views/Tasting/oldwcj/Scorpio.vue")
    },
    {
        path: "/Sagittarius/index.html",
        name: "Tasting",
        meta: {
            title: '射手座',
            backgroundColor: "#ffffff",
        },
        component: () => import("@views/Tasting/oldwcj/Sagittarius.vue")
    },
    {
        path: "/Capricorn/index.html",
        name: "Tasting",
        meta: {
            title: '摩羯座',
            backgroundColor: "#ffffff",
        },
        component: () => import("@views/Tasting/oldwcj/Capricorn.vue")
    },
    // {
    //     path: "/Tasting/business/canvass",
    //     name: "Tasting",
    //     meta: {
    //         title: '全国招商',
    //         backgroundColor: "#ffffff",
    //     },
    //     component: () => import("@views/Tasting/business/canvass.vue")
    // },
    // {
    //     path: "/Tasting/business/agreement",
    //     name: "Tasting",
    //     meta: {
    //         title: '协议',
    //         backgroundColor: "#ffffff",
    //     },
    //     component: () => import("@views/Tasting/business/agreement.vue")
    // },
    
    
];